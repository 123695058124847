<template>
  <list-container-widget
    id="storage"
    :title="widgetName"
    :app-id="app.id"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="storage" />
    </template>

    <!-- Container with data -->
    <app-scroll>
      <storage-table 
        :is-widget="true" 
        @updateFolders="updateFolders" 
      />
    </app-scroll>

    <!-- Widget description -->
    <description-addon v-model="isAppInfoModalVisible" :app-id="3" />
  </list-container-widget>
</template>

<script>
// import CreateFolderModal from '@/views/apps/storage/components/CreateFolderModal.vue';
import DescriptionAddon from '@/@core/widgets/DescriptionAddon.vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import AppScroll from '@/@core/components/app-scroll/AppScroll.vue';
import StoragePlaceholder from '@/assets/images/placeholders/light/storage.svg';
//import { translateTranslationTable } from '@/@core/libs/i18n/utils';
// import vSelect from 'vue-select';
// import MediaUploadModal from '@/views/apps/media/components/modal/MediaUploadModal.vue';
import StorageTable from '@/views/apps/storage/components/StorageTable.vue';

export default {
  name: 'StorageListWidget',
  components: {
    AppScroll,
    DescriptionAddon,
    ListContainerWidget,
    StorageTable,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      isAppInfoModalVisible: false,
      searchInput: '',
      results: [],
      isAddingMedia: false,
      folders: [],
    };
  },
  computed: {
    // Load data from store
    itemsData() {
      return this.$store.getters.storage;
    },
    itemsUnpaginated() {
      return this.$store.getters.storage.unpaginated;
    },
    items() {
      const itemsToIterate = this.searchInput ? this.results : this.itemsUnpaginated;
      return itemsToIterate;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    storagePlaceholder() {
      return StoragePlaceholder;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions('create', 'storage', this.loggedMemberRoles, this.collective);
    },
    canList() {
      return checkPermissions('view', 'storage', this.loggedMemberRoles, this.collective);
    },
    canView() {
      return checkPermissions('view', 'storage', this.loggedMemberRoles, this.collective);
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    //
    this.isLoading = false;
  },
  methods: {
    async mediaCreated() {
      //
    },
    openFolderModal() {
      this.$bvModal.show('modal-create-folder');
    },
    updateFolders(folders) {
      this.folders = folders;
    },
    async fetchData(force) {
      await this.$store.dispatch('getItems', {
        itemType: 'storage',
        page: this.lastLoadedPage,
        forceAPICall: force,
        perPage: 30,
        requestConfig: {
          folderKey: null,
          orderByDate: -1,
        },
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter((item) => item.title.toLowerCase().includes(searchTerm));
    },
  },
};
</script>

<style lang="scss"></style>
